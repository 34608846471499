import { Component, OnDestroy, OnInit } from '@angular/core';
import { BannerService } from '@core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'dc-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.sass'],
})
export class BannerComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];

  content: string;
  type: 'success' | 'info' | 'warning' | 'error';

  showBanner = false;

  constructor(private bannerService: BannerService) {}

  ngOnInit(): void {
    this.subs.push(
      this.bannerService.banner$.subscribe((message: any) => {
        const { content, type } = message;
        this.content = content;
        this.type = type;
        this.showBanner = true;
        setTimeout(() => {
          this.showBanner = false;
        }, 3000);
      }),
    );
  }

  ngOnDestroy(): void {
    this.subs.map(sub => sub.unsubscribe());
  }
}
