import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzUploadFile } from 'ng-zorro-antd';
import { Observable, of } from 'rxjs';

@Injectable()
export class ImageService {
  imageUrl = '/api/image';

  constructor(private http: HttpClient) {}

  uploadImage = (photoList: NzUploadFile[]): Observable<NzUploadFile[]> => {
    if (photoList.length === 0) {
      return of([]);
    }
    const formData = new FormData();
    photoList.forEach((photo: any) => {
      formData.append('images', photo);
    });
    return this.http.post<NzUploadFile[]>(`${this.imageUrl}`, formData);
  };
}
