import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationService } from '@core';

@Component({
  selector: 'dc-sign-in-form',
  templateUrl: './sign-in-form.component.html',
  styleUrls: ['./sign-in-form.component.sass'],
})
export class SignInFormComponent implements OnInit {
  validateForm!: FormGroup;
  passwordVisible = false;

  @Input() isLoading = false;
  @Input() isMaster = false;
  @Output() login: EventEmitter<any> = new EventEmitter();

  constructor(private fb: FormBuilder, private navigationService: NavigationService) {}

  ngOnInit(): void {
    if (this.isMaster) {
      this.validateForm = this.fb.group({
        email: [null, [Validators.required]],
        passwd: [null, [Validators.required]],
      });
    } else {
      this.validateForm = this.fb.group({
        email: [null, [Validators.required, Validators.email]],
        passwd: [null, [Validators.required]],
      });
    }
  }

  submitForm = (): void => {
    for (const i in this.validateForm.controls) {
      if (i) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    }
    if (this.validateForm.valid) {
      this.login.emit(this.validateForm.value);
    }
  };

  clearForm = (): void => {
    this.validateForm.setValue({
      loginId: null,
      passwd: null,
    });
  };

  onSignUpBtnClick = (event: any) => {
    event.preventDefault();
    this.navigationService.goToSignUpPage();
  };
}
