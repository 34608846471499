import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MasterMenuType, MenuType } from '@types';

@Injectable()
export class NavigationService {
  constructor(private router: Router, private location: Location) {}

  goToHomePage = (): void => {
    this.router.navigate(['home']);
  };

  goToPurchasePage = (productId: number): void => {
    this.router.navigate([`purchase/${productId}`]);
  };

  goToPurchaseFailPage = (): void => {
    this.router.navigate(['purchase/fail']);
  };

  goToPurchaseSuccessPage = (orderId: number): void => {
    this.router.navigate([`purchase/success/order/${orderId}`]);
  };

  goToSignInPage = (): void => {
    this.router.navigate(['sign-in']);
  };

  goToMaterSignInPage = (): void => {
    this.router.navigate(['sign-in/master']);
  };

  goToSignUpPage = (): void => {
    this.router.navigate(['sign-up']);
  };

  goToMasterPage = () => {
    this.router.navigate(['master']);
  };

  goToMasterOrderPage = (): void => {
    this.router.navigate(['master/order']);
  };

  goToMasterPaymentPage = (): void => {
    this.router.navigate(['master/payment']);
  };

  goToMasterVpcPage = (): void => {
    this.router.navigate(['master/vpc']);
  };

  goToMyPage = (): void => {
    this.router.navigate(['my-page']);
  };

  goToOrderPage = (): void => {
    this.router.navigate(['my-page/order']);
  };

  goToPaymentPage = (): void => {
    this.router.navigate(['my-page/payment']);
  };

  goToVpcPage = (): void => {
    this.router.navigate(['my-page/vpc']);
  };

  goToChangePasswordPage = (): void => {
    this.router.navigate(['my-page/change-password']);
  };

  goToEmailPage = (): void => {
    this.router.navigate(['email']);
  };

  goToPageByMenuType = (menuType: MenuType) => {
    switch (menuType) {
      case MenuType.MY_PAGE:
        this.goToMyPage();
        break;
      case MenuType.ORDER:
        this.goToOrderPage();
        break;
      case MenuType.PAYMENT:
        this.goToPaymentPage();
        break;
      case MenuType.VPC:
        this.goToVpcPage();
        break;
      case MenuType.CHANGE_PASSWORD:
        this.goToChangePasswordPage();
        break;
      default:
        this.goToHomePage();
        break;
    }
  };

  goToPageByMasterMenuType = (menuType: MasterMenuType) => {
    switch (menuType) {
      case MasterMenuType.ORDER:
        this.goToMasterOrderPage();
        break;
      case MasterMenuType.PAYMENT:
        this.goToMasterPaymentPage();
        break;
      case MasterMenuType.VPC:
        this.goToMasterVpcPage();
        break;
      default:
        this.goToHomePage();
        break;
    }
  };

  goBack = (): void => {
    this.location.back();
  };
}
