import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VpcModel } from '@models';

@Component({
  selector: 'dc-connect-way-modal',
  templateUrl: './connect-way-modal.component.html',
  styleUrls: ['./connect-way-modal.component.sass'],
})
export class ConnectWayModalComponent implements OnInit {
  @Input() isModalVisible = false;
  @Input() vpc = new VpcModel();
  @Output() handleCancel: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
