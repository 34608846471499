import { Component } from '@angular/core';

import { AuthService, NavigationService } from '@core';
@Component({
  selector: 'dc-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass'],
})
export class FooterComponent {
  offset = 0;
  visible = false;
  bodyStyle = { padding: '0px' };

  constructor(private authService: AuthService, public navigationService: NavigationService) {}

  signOut = () => {
    this.authService.signOut();
  };

  close(): void {
    this.visible = false;
  }
}
