import { Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';

@Injectable()
export class ModalService {
  constructor(private modalService: NzModalService) {}

  create = (title: string, component: any, width?: number, params?: any) => {
    return this.modalService.create({
      nzTitle: title,
      nzContent: component,
      nzFooter: null,
      nzWidth: width ? width : 1000,
      nzStyle: { 'min-width': width ? `${width}px` : '1000px' },
      nzComponentParams: params,
    });
  };
}
