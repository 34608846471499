import { AuthRole } from '@types';

class BaseUserModel {
  email: string;
  passwd: string;
  role: AuthRole;

  get isMaster(): boolean {
    return this.role === AuthRole.ROLE_MASTER;
  }

  get isUser(): boolean {
    return this.role === AuthRole.ROLE_USER;
  }
}

export class AuthUserModel extends BaseUserModel {}

export class AuthMasterModel extends BaseUserModel {}
