import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OrderModel, PageModel, PagingModel } from '@models';
import { OrderType } from '@types';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaymentModel } from '../../models/payment';
import { AuthService } from '../auth';

@Injectable()
export class PaymentService {
  userUrl = 'api/user/payment';
  masterUrl = 'api/master/payment';

  constructor(private http: HttpClient, private authService: AuthService) {}

  loadPaymentList = (pageNumber: number, pageSize: number): Observable<PageModel<PaymentModel>> => {
    return this.http
      .get<PagingModel<PaymentModel>>(`${this.url}?pageNumber=${pageNumber}&pageSize=${pageSize}`)
      .pipe(
        map(result => {
          result.page.content = result.page.content.map(payment =>
            plainToClass(PaymentModel, payment),
          );
          return result.page;
        }),
      );
  };

  get url() {
    return this.authService.isMaster ? this.masterUrl : this.userUrl;
  }
}
