export class PagingModel<T> {
  page: PageModel<T>;
}

export class PageModel<T> {
  content: T[];
  pageable: PageableModel;
  last: boolean;
  totalPages: number;
  totalElements: number;

  number: number;
  first: boolean;
  size: number;
  numberOfElements: number;
  empty: boolean;
}

export class PageableModel {
  offset: number;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  unpaged: boolean;

  sort: sortModel;
}

export class sortModel {
  empty: boolean;
  sorted: boolean;
  unsorted: boolean;
}
