<nz-modal [(nzVisible)]="isModalVisible" [nzTitle]="modalTitle" [nzContent]="modalContent" [nzFooter]="modalFooter"
  [nzMaskClosable]="true" (nzOnCancel)="handleCancel.emit()">
  <ng-template #modalTitle>{{vpc.vpcName}} 제품 정보</ng-template>
  <ng-template #modalContent>
    <div class="info-row">
      <span class="info-title">제품명 : </span>
      <span class="info-body">{{vpc.product.productName}}</span>
    </div>
    <div class="info-row">
      <span class="info-title">메모리 : </span>
      <span class="info-body">{{vpc.machineLearning ? '별도문의' : vpc.product.ram}}GB</span>
    </div>
    <div class="info-row">
      <span class="info-title">CPU : </span>
      <span class="info-body">{{vpc.machineLearning ? '별도문의' : vpc.product.cpu}} vCPU</span>
    </div>
    <div class="info-row">
      <span class="info-title">저장소 : </span>
      <span class="info-body">{{vpc.machineLearning ? '별도문의' : vpc.product.storage}} GB</span>
    </div>
    <div class="info-row">
      <span class="info-title">가격 : </span>
      <span class="info-body">₩{{vpc.product.displayPrice | number:'':'en'}}원 (VAT 별도)</span>
    </div>
  </ng-template>

  <ng-template #modalFooter>
    <button nz-button nzType="primary" (click)="handleCancel.emit()">확인</button>
  </ng-template>
</nz-modal>