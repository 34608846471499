import { Component, OnInit } from '@angular/core';

import { AuthService, NavigationService } from '@core';

@Component({
  selector: 'dc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
})
export class HeaderComponent {
  offset = 0;
  visible = false;
  bodyStyle = { padding: '0px' };

  constructor(public authService: AuthService, public navigationService: NavigationService) {}

  signOut = () => {
    this.authService.signOut();
    this.navigationService.goToHomePage();
  };

  close(): void {
    this.visible = false;
  }

  sendEmail = () => {
    if (!this.authService.isAuthenticated) {
      return;
    }
    const emailBody = `문의 아이디: ${this.authService.email}\r\n문의 내용: `;
    window.location.href = `mailto:support@dreambucket.io?subject=[드림클라우드] 문의&body=${encodeURIComponent(
      emailBody,
    )}`;
  };

  goToMyPage = () => {
    if (this.authService.isMaster) {
      this.navigationService.goToMasterPage();
    } else {
      this.navigationService.goToVpcPage();
    }
  };
}
