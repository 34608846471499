import { Component, Input, OnInit } from '@angular/core';
import { NavigationService } from '@core';
import { ProductModel } from '@models';

@Component({
  selector: 'dc-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.sass'],
})
export class ProductComponent implements OnInit {
  @Input() product = new ProductModel();

  constructor(public navigationService: NavigationService) {}

  ngOnInit(): void {}
}
