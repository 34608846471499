import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProductModel } from '@models';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth';

@Injectable()
export class ProductService {
  userUrl = 'api/user/product';
  masterUrl = 'api/master/product';

  constructor(private http: HttpClient, private authService: AuthService) {}

  loadProductList = (): Observable<ProductModel[]> => {
    return this.http
      .get<{ productList: ProductModel[] }>(`${this.userUrl}`, { headers: { ignoreToken: 'true' } })
      .pipe(map(result => result.productList.map(product => plainToClass(ProductModel, product))));
  };

  loadProduct = (productId: number): Observable<ProductModel> => {
    return this.http
      .get<{ product: ProductModel }>(`${this.url}/${productId}`)
      .pipe(map(result => plainToClass(ProductModel, result.product)));
  };

  get url(): string {
    return this.authService.isMaster ? this.masterUrl : this.userUrl;
  }
}
