import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth';

@Injectable()
export class MyInfoService {
  userUrl = 'api/user/myinfo';
  masterUrl = 'api/master/myinfo';

  constructor(private http: HttpClient, private authService: AuthService) {}

  changePasswd = (passwd: string) => {
    return this.http.patch(`${this.url}/passwd`, { passwd });
  };

  get url() {
    return this.authService.isMaster ? this.masterUrl : this.userUrl;
  }
}
