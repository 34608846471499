export enum VpcStateType {
  PENDING = 'PENDING',
  WAITING = 'WAITING',
  AVAILABLE = 'AVAILABLE',
  TERMINATED = 'TERMINATED',
}

export enum VpcEditType {
  NAME = 'NAME',
  TELEGRAM_CODE = 'TELEGRAM_CODE',
  REMOTE_ID = 'REMOTE_ID',
  REMOTE_PASSWORD = 'REMOTE_PASSWORD',
}
