import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AuthService, BannerService, IamportService, OrderService, VpcService } from '@core';
import { VpcModel } from '@models';
import { OrderType, VpcEditType, VpcStateType } from '@types';
import { plainToClass } from 'class-transformer';
import { Observable, Subscription } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import { VpcEditModalComponent } from '../vpc-edit-modal';

@Component({
  selector: 'dc-vpc-list',
  templateUrl: './vpc-list.component.html',
  styleUrls: ['./vpc-list.component.sass'],
})
export class VpcListComponent implements OnInit {
  isLoading = false;
  isRenewLoading = false;
  pageNumber = 1;
  pageSize = 20;
  totalSize = 0;

  merchantId: string;

  vpcStateType = VpcStateType;
  vpcEditType = VpcEditType;

  vpcList: VpcModel[] = [];

  selectedVpcStateType?: VpcStateType;

  selectedVpc = new VpcModel();
  selectedVpcEditType: VpcEditType;

  isDetailModalVisible = false;
  isConnectWayModalVisible = false;
  isEditModalVisible = false;
  isEditModalLoading = false;

  subscription?: Subscription;

  @Input() isMater = false;
  @ViewChild(VpcEditModalComponent) modalElement: VpcEditModalComponent;

  constructor(
    private authService: AuthService,
    private bannerService: BannerService,
    private vpcService: VpcService,
    private orderService: OrderService,
    private iamportService: IamportService,
  ) {}

  ngOnInit(): void {
    this.loadVpc();
  }

  onChangeVpcStateType = (vpcStateType?: VpcStateType) => {
    if (this.selectedVpcStateType === vpcStateType) {
      return;
    }
    this.selectedVpcStateType = vpcStateType;
    this.loadVpc();
  };

  onClickEdit = (vpc: VpcModel, vpcEditType: VpcEditType) => {
    this.selectedVpc = plainToClass(VpcModel, vpc);
    this.selectedVpcEditType = vpcEditType;
    this.isEditModalVisible = true;
    setTimeout(() => {
      this.modalElement.focus();
    }, 300);
  };

  onClickDetail = (vpc: VpcModel) => {
    this.selectedVpc = plainToClass(VpcModel, vpc);
    this.isDetailModalVisible = true;
  };

  onClickConnectWay = (vpc: VpcModel) => {
    if (vpc.vpcState === VpcStateType.TERMINATED) {
      this.bannerService.warning('이미 종료 된 가상 머신입니다.');
      return;
    }
    this.selectedVpc = plainToClass(VpcModel, vpc);
    this.isConnectWayModalVisible = true;
  };

  handleEditModalOk = () => {
    this.isEditModalLoading = true;

    let observable: Observable<any>;
    switch (this.selectedVpcEditType) {
      case VpcEditType.NAME:
        observable = this.vpcService.updateName(this.selectedVpc.vpcName, this.selectedVpc.vpcId);
        break;
      case VpcEditType.TELEGRAM_CODE:
        observable = this.vpcService.updateTelegramCode(
          this.selectedVpc.telegramCode,
          this.selectedVpc.vpcId,
        );
        break;
      default:
        this.bannerService.error('잘못된 접근입니다.');
        this.hideModal();
        return;
    }

    observable.subscribe(
      () => {
        this.vpcList = this.vpcList.map(vpc => {
          if (vpc.vpcId === this.selectedVpc.vpcId) {
            return plainToClass(VpcModel, this.selectedVpc);
          }
          return vpc;
        });
        this.hideModal();
      },
      () => {
        this.bannerService.error('수정이 실패했습니다.');
        this.hideModal();
      },
    );
  };

  handleCancel = () => {
    this.hideModal();
  };

  hideModal = () => {
    this.selectedVpc = new VpcModel();
    this.isEditModalLoading = false;
    this.isEditModalVisible = false;
    this.selectedVpc = new VpcModel();
    this.isDetailModalVisible = false;
    this.isConnectWayModalVisible = false;
  };

  onPageIndexChange = async (pageIdx: number) => {
    this.pageNumber = pageIdx;
    this.loadVpc();
  };

  onClickRenew = (vpc: VpcModel) => {
    if (this.isRenewLoading) {
      return;
    }
    if (vpc.vpcState !== VpcStateType.AVAILABLE) {
      this.bannerService.warning('연장 불가능 합니다.');
      return;
    }
    const email = this.authService.email;
    this.isRenewLoading = true;
    this.orderService
      .loadOrderNumber(OrderType.EXTENSION)
      .pipe(
        tap(merchantId => (this.merchantId = merchantId)),
        concatMap(merchantId =>
          this.iamportService.purchase(
            vpc.product,
            merchantId,
            1,
            email,
            email,
            vpc.vpcName,
            vpc.telegramCode,
          ),
        ),
      )
      .pipe(
        concatMap(response => {
          const { imp_uid } = response;
          return this.orderService.renew(
            vpc.vpcId,
            imp_uid,
            this.merchantId,
            vpc.product.productId,
            vpc.vpcName,
            vpc.telegramCode,
          );
        }),
      )
      .subscribe(
        () => {
          this.isRenewLoading = false;
          this.bannerService.success('성공적으로 연장 되었습니다.');
        },
        () => {
          this.isRenewLoading = false;
          this.bannerService.error('연장이 취소 되었습니다.');
        },
      );
  };

  tagClass = (vpc: VpcModel) => {
    if (!this.authService.isMaster) {
      return [];
    }
    return vpc.vpcState === VpcStateType.WAITING ? ['cursor'] : [];
  };

  private loadVpc = () => {
    this.isLoading = true;
    this.subscription && this.subscription.unsubscribe();
    this.subscription = this.vpcService
      .loadVpcList(this.pageNumber, this.pageSize, this.selectedVpcStateType)
      .subscribe(
        result => {
          this.vpcList = result.content;
          this.totalSize = result.totalElements;
          this.isLoading = false;
        },
        () => (this.isLoading = false),
      );
  };
}
