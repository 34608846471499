import { OrderState, PaymentType } from '@types';
import { ProductModel } from '../product';
import { VpcModel } from '../vpc';

export class OrderModel {
  orderId: number;
  orderNo: string;
  impUid: string;
  totalPayAmount: number;
  payMethod: PaymentType;
  orderState: OrderState;
  vbankNum: string;
  vbankDate: string;
  vbankHolder: string;
  vbankName: string;

  detailList: OrderDetailModel[] = [];

  get orderStateStr(): string {
    switch (this.orderState) {
      case OrderState.ORDERED:
        return '대기';
      case OrderState.CANCELED:
        return '취소';
      case OrderState.COMPLETED:
        return '완료';
      default:
        return '알수없음';
    }
  }

  get payMethodStr(): string {
    switch (this.payMethod) {
      case PaymentType.VBANK:
        return '가상계좌';
      case PaymentType.CARD:
        return '신용카드';
      default:
        return '알수없음';
    }
  }
}

export class OrderDetailModel {
  orderDetailId: number;
  product: ProductModel;
  vpc: VpcModel;
}
