<div class="sign-up-form-title">
  회원가입
</div>
<form nz-form [formGroup]="validateForm" [nzLayout]="'vertical'" class="sign-up-form" (ngSubmit)="submitForm()">
  <nz-form-item>
    <nz-form-label nzRequired>이메일</nz-form-label>
    <nz-form-control nzErrorTip="이메일 형식이 아닙니다.">
      <nz-input-group>
        <input class="form-input" nz-input type="email" name="email" [email]="true" formControlName="email"
          placeholder="이메일" />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label nzRequired>비밀번호</nz-form-label>
    <nz-form-control nzErrorTip="최소 8글자, 특수문자, 문자, 숫자 포함">
      <nz-input-group>
        <input class="form-input" nz-input [type]="'password'" placeholder="비밀번호" formControlName="passwd" />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label nzRequired>비밀번호 확인</nz-form-label>
    <nz-form-control nzErrorTip="비밀번호가 다릅니다.">
      <nz-input-group>
        <input class="form-input" nz-input [type]="'password'" placeholder="비밀번호 확인" formControlName="passwdConfirm" />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-control>
      <nz-input-group>
        <div class="sign-up-check-wrapper">
          <label nz-checkbox formControlName="serviceTermAgreed">서비스 이용약관</label>
          <button nz-button nzType="link" (click)="openTermModal(termType.SERVICE, $event)">
            보기
          </button>
        </div>
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-control>
      <nz-input-group>
        <div class="sign-up-check-wrapper">
          <label nz-checkbox formControlName="privacyTermAgreed">개인정보 보호 약관</label>
          <button nz-button nzType="link" (click)="openTermModal(termType.PRIVACY, $event)">
            보기
          </button>
        </div>
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <button nz-button class="form-input sign-up-form-button sign-up-form-margin" nzType="primary" [nzLoading]="isLoading"
    [disabled]="!validateForm.valid">
    회원가입
  </button>
</form>