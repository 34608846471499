<div class="button-wrapper">
  <button class="btn" nz-button nzType="primary" nzGhost [nzLoading]="isLoading" (click)="onChangeVpcStateType()">
    전체
  </button>
  <button class="btn" nz-button nzType="primary" nzGhost [nzLoading]="isLoading"
    (click)="onChangeVpcStateType(vpcStateType.PENDING)">
    생성중
  </button>
  <button class="btn" nz-button nzType="primary" nzGhost [nzLoading]="isLoading"
    (click)="onChangeVpcStateType(vpcStateType.WAITING)">
    대기
  </button>
  <button class="btn" nz-button nzType="primary" nzGhost [nzLoading]="isLoading"
    (click)="onChangeVpcStateType(vpcStateType.AVAILABLE)">
    동작
  </button>
  <button class="btn" nz-button nzType="primary" nzGhost [nzLoading]="isLoading"
    (click)="onChangeVpcStateType(vpcStateType.TERMINATED)">
    종료
  </button>
</div>
<div class="vpc-list-wrapper">
  <div class="table-wrapper">
    <table width=100%>
      <thead>
        <tr>
          <th *ngIf="isMater">아이디</th>
          <th>이름</th>
          <th>텔레그램</th>
          <th>아이피</th>
          <th>접속 아이디</th>
          <th>접속 비밀번호</th>
          <th>상태</th>
          <th>PC 정보</th>
          <th>접속 방법</th>
          <th *ngIf="!isMater">1달 연장</th>
          <th>만료 날짜</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let vpc of vpcList">
          <td *ngIf="isMater" nzBreakWord>
            <span class="a-btn">{{vpc.user.email}}</span>
          </td>
          <td nzBreakWord>
            <span class="a-btn">{{vpc.vpcName}}</span>
            <a (click)="onClickEdit(vpc, vpcEditType.NAME)">
              <i nz-icon nzType="edit" nzTheme="outline"></i>
            </a>
          </td>
          <td nzBreakWord>
            <span class="a-btn">{{vpc.telegramCode}}</span>
            <a (click)="onClickEdit(vpc, vpcEditType.TELEGRAM_CODE)">
              <i nz-icon nzType="edit" nzTheme="outline"></i>
            </a>
          </td>
          <td nzBreakWord>
            {{vpc.remoteIP}}
          </td>
          <td nzBreakWord>
            <span class="a-btn">{{vpc.remoteId}}</span>
          </td>
          <td nzBreakWord>
            <span class="a-btn">{{vpc.remotePasswd}}</span>
          </td>
          <td nzBreakWord>
            <nz-tag [nzColor]="vpc.vpcStateToColor" [ngClass]="tagClass(vpc)">
              {{vpc.vpcStateToStr}}
            </nz-tag>
          </td>
          <td nzBreakWord>
            <a nz-button nzType="link" (click)="onClickDetail(vpc)">정보</a>
          </td>
          <td nzBreakWord>
            <a nz-button nzType="link" (click)="onClickConnectWay(vpc)">보기</a>
          </td>
          <td *ngIf="!isMater" nzBreakWord>
            <a nz-button nzType="link" (click)="onClickRenew(vpc)" [disabled]="isRenewLoading">연장</a>
          </td>
          <td nzBreakWord>
            {{vpc.expireDate | date: 'yyyy-MM-dd'}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <nz-pagination [nzPageIndex]="pageNumber" [nzPageSize]="pageSize" [nzTotal]="totalSize"
    (nzPageIndexChange)="onPageIndexChange($event)">
  </nz-pagination>
</div>
<dc-vpc-detail-modal #nameEditModal [(vpc)]="selectedVpc" [isModalVisible]="isDetailModalVisible"
  (handleCancel)="handleCancel()">
</dc-vpc-detail-modal>
<dc-vpc-edit-modal #editModal [(vpc)]="selectedVpc" [selectedVpcEditType]="selectedVpcEditType"
  [isModalVisible]="isEditModalVisible" [isModalLoading]="isEditModalLoading" (handleOk)="handleEditModalOk()"
  (handleCancel)="handleCancel()">
</dc-vpc-edit-modal>
<dc-connect-way-modal [isModalVisible]="isConnectWayModalVisible" [vpc]="selectedVpc" (handleCancel)="handleCancel()">
</dc-connect-way-modal>