import { Component, Input, OnInit } from '@angular/core';
import { NavigationService } from '@core';
import { MenuType } from '@types';

@Component({
  selector: 'dc-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.sass'],
})
export class MenuComponent implements OnInit {
  @Input() selectedMenuType = MenuType.ORDER;

  menuType = MenuType;

  constructor(public navigationService: NavigationService) {}

  ngOnInit(): void {}

  menuTypeToStr = (menuType: MenuType): string => {
    switch (menuType) {
      case MenuType.MY_PAGE:
        return '마이 페이지';
      case MenuType.ORDER:
        return '주문 내역';
      case MenuType.PAYMENT:
        return '결제 내역';
      case MenuType.VPC:
        return '가상머신';
      case MenuType.CHANGE_PASSWORD:
        return '비밀번호 변경';
      default:
        return '알수없음';
    }
  };

  isSelected = (menuType: MenuType): boolean => {
    return this.selectedMenuType === menuType;
  };
}
