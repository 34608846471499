<div class="col card">
  <div>
    <div class="badge bold">
      {{product.productName}}
    </div>
    <img [src]="product.imageUrl" class="center-cropped" />
    <h4>{{product.memo }}</h4>
    <h2 class="bold">₩{{product.displayPrice | number:'':'en'}}원 / 월 (VAT 별도)</h2>
    <h4 *ngIf="!product.machineLearning">{{product.cpu}} vCPU</h4>
    <h4 *ngIf="!product.machineLearning">{{product.ram}}GB RAM</h4>
    <h4 *ngIf="!product.machineLearning">{{product.storage}}GB 저장소</h4>
    <h4 *ngIf="product.machineLearning">별도 문의</h4>
  </div>
  <button class="buy-button" nz-button nzType="primary" (click)="navigationService.goToPurchasePage(product.productId)">
    구매
  </button>
</div>