import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VpcModel } from '@models';

@Component({
  selector: 'dc-vpc-detail-modal',
  templateUrl: './vpc-detail-modal.component.html',
  styleUrls: ['./vpc-detail-modal.component.sass'],
})
export class VpcDetailModalComponent implements OnInit {
  @Input() isModalVisible = false;

  // ngModel
  @Input() vpc: VpcModel;
  @Output() vpcChange = new EventEmitter<VpcModel>();

  @Output() handleCancel: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
