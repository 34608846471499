<div class="payment-list-wrapper">
  <div class="table-wrapper">
    <table width=100%>
      <thead>
        <tr>
          <th>구매 물품</th>
          <th>금액</th>
          <th>결제 방법</th>
          <th>구매 날짜</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let payment of paymentList">
          <td *ngIf="payment.machineLearning" nzBreakWord>
            {{payment.productName}}
          </td>
          <td *ngIf="!payment.machineLearning" nzBreakWord>
            {{payment.productName}} - {{payment.cpu}} vCPU x 메모리 {{payment.ram}}GB x 저장소 {{payment.storage}}GB
          </td>
          <td nzBreakWord>
            <h4>₩{{payment.payAmount | number:'':'en'}}원</h4>
          </td>
          <td nzBreakWord>
            {{payment.paymentMethodStr}}
          </td>
          <td nzBreakWord>
            {{payment.createdAt | date: 'yyyy-MM-dd HH:mm:ss'}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <nz-pagination [nzPageIndex]="pageNumber" [nzPageSize]="pageSize" [nzTotal]="totalSize"
    (nzPageIndexChange)="onPageIndexChange($event)">
  </nz-pagination>
</div>