import { Injectable } from '@angular/core';
import { TokenModel } from '@models';

@Injectable()
export class SessionStorageService {
  refreshTokenKey = 'refreshToken';
  accessTokenKey = 'accessToken';

  get refreshToken(): string {
    return sessionStorage.getItem(this.refreshTokenKey);
  }

  get accessToken(): string {
    return sessionStorage.getItem(this.accessTokenKey);
  }

  saveTokens = (token: TokenModel) => {
    this.saveRefreshToken(token.refreshToken);
    this.saveAccessToken(token.accessToken);
  };

  saveRefreshToken = (refreshToken: string) => {
    sessionStorage.setItem(this.refreshTokenKey, refreshToken);
  };

  saveAccessToken = (accessToken: string) => {
    sessionStorage.setItem(this.accessTokenKey, accessToken);
  };

  deleteTokens = () => {
    this.deleteRefreshToken();
    this.deleteAccessToken();
  };

  private deleteRefreshToken = () => {
    sessionStorage.removeItem(this.refreshTokenKey);
  };

  private deleteAccessToken = () => {
    sessionStorage.removeItem(this.accessTokenKey);
  };
}
