import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class BannerService {
  private bannerSrc = new Subject();
  banner$ = this.bannerSrc.asObservable();

  constructor() {}

  error = (content: string) => {
    this.showBanner(content, 'error');
  };

  success = (content: string) => {
    this.showBanner(content, 'success');
  };

  warning = (content: string) => {
    this.showBanner(content, 'warning');
  };

  info = (content: string) => {
    this.showBanner(content, 'info');
  };

  private showBanner = (content: string, type: 'error' | 'success' | 'warning' | 'info') => {
    this.bannerSrc.next({ content, type });
  };
}
