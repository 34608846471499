import { Component, Input, OnInit } from '@angular/core';
import { MemberService } from '@core';
import { TermModel } from '@models';
import { TermType } from '@types';
import { Observable } from 'rxjs';

@Component({
  selector: 'dc-term',
  templateUrl: './term.component.html',
  styleUrls: ['./term.component.sass'],
})
export class TermComponent implements OnInit {
  @Input() termType: TermType;

  term = new TermModel();

  constructor(private memberService: MemberService) {}

  ngOnInit(): void {
    this.loadTerm();
  }

  loadTerm = () => {
    let term!: Observable<TermModel>;
    switch (this.termType) {
      case TermType.PRIVACY:
        term = this.memberService.getTermPrivacy();
        break;
      case TermType.SERVICE:
        term = this.memberService.getTermService();
    }
    term && term.subscribe(term => (this.term = term));
  };
}
