<footer class="footer background">
  <div style="flex: 1">
    <span>support@dreambucket.io</span>
    <span> | (주) 드림버켓 </span>
    <br />
    <span>경기도 성남시 분당구</span>
    <br />
    <div class="flex">
      <span>통신판매번호: 제 2022-성남분당C-0012 호</span>
      <span class="txt" style="margin-left: 10px;">사업자등록번호: 703-81-02130</span>
    </div>
    <span class="txt">T. 070-8865-9990</span>
  </div>
  <div style="flex: 1; text-align: right;">
    <a href="/policy">이용약관</a>
    <span style="margin: 0px 10px">|</span>
    <a href="/term">개인정보취급방침</a>
    <br />
    <span>@All Rights Reserved 2022 DreamBucket</span>
  </div>
</footer>