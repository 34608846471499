import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenModel } from '@models';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';

import { AuthService } from '../auth';
import { BannerService } from '../banner';
import { ContentService } from '../content';
import { SessionStorageService } from '../session-storage';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private authService: AuthService,
    private bannerService: BannerService,
    private contentService: ContentService,
    private sessionStorageService: SessionStorageService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken: string = this.sessionStorageService.accessToken;
    const ignoreToken = request.headers.get('ignoreToken');
    if (request.url.includes('refresh')) {
      const refreshToken = this.sessionStorageService.refreshToken;
      request = this.addToken(request, refreshToken);
    } else if (!ignoreToken && accessToken) {
      request = this.addToken(request, accessToken);
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => this.handleResponse(event)),
      catchError((error: HttpErrorResponse) => {
        const message = error && error.error && error.error.message ? error.error.message : '';
        const resultCode =
          error && error.error && error.error.resultCode ? error.error.resultCode : 0;
        if (error.status === 403) {
          if (request.url.includes('refresh')) {
            this.isRefreshing = false;
            if (message) {
              this.bannerService.error(message);
            }
            this.contentService.signOut();
            return throwError(error);
          } else {
            if (resultCode !== 0) {
              return this.handleRefreshTokenError(request, next);
            } else {
              this.contentService.signOut();
              return throwError(error);
            }
          }
        } else {
          if (message) {
            this.bannerService.error(message);
          }
          if (error.status === 401) {
            this.contentService.signOut();
          }
          return throwError(error);
        }
      }),
    );
  }

  handleResponse = (event: HttpEvent<any>) => {
    if (event instanceof HttpResponse) {
      event = event.clone({ body: event.body.response });
    }
    return event;
  };

  handleRefreshTokenError = (request: HttpRequest<any>, next: HttpHandler) => {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.authService.refresh().pipe(
        switchMap((token: TokenModel) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.accessToken);
          return next.handle(this.addToken(request, token.accessToken)).pipe(
            map((event: HttpEvent<any>) => this.handleResponse(event)),
            catchError((error: HttpErrorResponse) => this.handleError(error)),
          );
        }),
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(accessToken => {
          return next.handle(this.addToken(request, accessToken)).pipe(
            map((event: HttpEvent<any>) => this.handleResponse(event)),
            catchError((error: HttpErrorResponse) => this.handleError(error)),
          );
        }),
      );
    }
  };

  handleError = (error: HttpErrorResponse) => {
    if (error.status === 401 || error.status === 403) {
      this.contentService.signOut();
    }
    return throwError(error);
  };

  private addToken = (request: HttpRequest<any>, token: string) => {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
}
