import { Component, OnInit } from '@angular/core';
import { OrderService } from '@core';
import { OrderModel, ProductModel } from '@models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'dc-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.sass'],
})
export class OrderListComponent implements OnInit {
  pageNumber = 1;
  pageSize = 20;
  totalSize = 0;

  isDetailModalVisible = false;

  orderList: OrderModel[] = [];
  subscription?: Subscription;

  selectedProducts: ProductModel[] = [];

  constructor(private orderService: OrderService) {}

  ngOnInit(): void {
    this.loadOrderList();
  }

  loadOrderList = () => {
    this.subscription && this.subscription.unsubscribe();
    this.subscription = this.orderService
      .loadOrderList(this.pageNumber, this.pageSize)
      .subscribe(result => {
        this.orderList = result.content;
        this.totalSize = result.totalElements;
      });
  };

  onPageIndexChange = async (pageIdx: number) => {
    this.pageNumber = pageIdx;
    this.loadOrderList();
  };

  onClickOrder = (order: OrderModel) => {
    this.isDetailModalVisible = true;
    this.selectedProducts = order.detailList
      .map(detail => detail.product)
      .reduce((prev, next) => {
        return [...prev, next];
      }, []);
  };

  handleCancel = () => {
    this.isDetailModalVisible = false;
  };
}
