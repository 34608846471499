import { Injectable } from '@angular/core';
import { ProductModel, PurchaseTransactionModel } from '@models';
import * as moment from 'moment';
import { Observable } from 'rxjs';

declare global {
  interface Window {
    IMP: any;
  }
}

@Injectable()
export class IamportService {
  constructor() {
    this.IMP.init('imp93269477');
  }

  purchase = (
    product: ProductModel,
    merchantId: string,
    quantity: number,
    buyerEmail: string,
    buyerName: string,
    vpcName: string,
    telegramCode: string,
  ): Observable<PurchaseTransactionModel> => {
    return new Observable(observer => {
      this.IMP.request_pay(
        {
          pg: 'kcp',
          pay_method: 'vbank',
          merchant_uid: merchantId,
          name: `드림클라우드 가상PC ${product.productName}형 x ${quantity}개`,
          amount: product.sellPrice * quantity,
          buyer_email: buyerEmail,
          buyer_name: buyerName,
          vbank_due: moment().add(2, 'days').format('YYYYMMDD23:59'),
          m_redirect_url: `https://dreamcloud.link/purchase/complete?productId=${product.productId}&vpcName=${vpcName}&telegramCode=${telegramCode}`,
        },
        (transaction: PurchaseTransactionModel) => {
          if (transaction.success) {
            observer.next(transaction);
          } else {
            observer.error(transaction.error_msg);
          }
        },
      );
    });
  };

  private get IMP() {
    return window.IMP;
  }
}
