<nz-modal [(nzVisible)]="isModalVisible" [nzTitle]="modalTitle" [nzContent]="modalContent" [nzFooter]="modalFooter"
  [nzMaskClosable]="true" (nzOnCancel)="handleCancel.emit()">
  <ng-template #modalTitle>접속 방법</ng-template>

  <ng-template #modalContent>
    <div class="info-row">
      <span class="info-title">1.</span>
      <span class="info-body">원격 데스크톱 연결 실행</span>
    </div>
    <div class="info-row">
      <span class="info-title">2.</span>
      <span class="info-body">컴퓨터 항목에 <b class="point">{{vpc.remoteIP}}</b> 입력</span>
    </div>
    <div class="info-row">
      <span class="info-title">3.</span>
      <span class="info-body">연결(N) 선택</span>
    </div>
    <div class="info-row">
      <span class="info-title">4.</span>
      <span class="info-body">사용자 이름에 <b class="point">{{vpc.remoteId}}</b> 입력</span>
    </div>
    <div class="info-row">
      <span class="info-title">5.</span>
      <span class="info-body">암호에 <b class="point">{{vpc.remotePasswd}}</b> 입력</span>
    </div>
    <div class="info-row">
      <span class="info-title">6.</span>
      <span class="info-body">확인을 선택하여 원격 PC에 접속</span>
    </div>
  </ng-template>

  <ng-template #modalFooter>
    <button nz-button nzType="primary" (click)="handleCancel.emit()">확인</button>
  </ng-template>

</nz-modal>