import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TermModel } from '@models';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class MemberService {
  url = 'api/user/member';
  termUrl = 'api/user/term';

  constructor(private http: HttpClient) {}

  signUp = (
    email: string,
    passwd: string,
    serviceTermAgreed: boolean,
    privacyTermAgreed: boolean,
  ): Observable<any> => {
    return this.http.post(
      `${this.url}`,
      {
        email,
        passwd,
        serviceTermAgreed,
        privacyTermAgreed,
      },
      { headers: { ignoreToken: 'true' } },
    );
  };

  getTermService = (): Observable<TermModel> => {
    return this.http
      .get<{ term: TermModel }>(`${this.termUrl}/service`, { headers: { ignoreToken: 'true' } })
      .pipe(map(term => plainToClass(TermModel, term.term)));
  };

  getTermPrivacy = (): Observable<TermModel> => {
    return this.http
      .get<{ term: TermModel }>(`${this.termUrl}/privacy`, { headers: { ignoreToken: 'true' } })
      .pipe(map(term => plainToClass(TermModel, term.term)));
  };

  verify = (emailVerifyToken: string) => {
    return this.http.post(
      `${this.url}/verify`,
      { emailVerifyToken },
      { headers: { ignoreToken: 'true' } },
    );
  };
}
