<div *ngIf="hideTab then noTab else tab"></div>
<ng-template #noTab>
  <dc-banner></dc-banner>
  <router-outlet></router-outlet>
</ng-template>
<ng-template #tab>
  <div id="wrap">
    <section>
      <dc-header></dc-header>
      <dc-banner></dc-banner>
      <router-outlet></router-outlet>
    </section>
    <dc-footer *ngIf="!hideFooter"></dc-footer>
  </div>
</ng-template>