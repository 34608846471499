import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { VpcModel } from '@models';
import { VpcEditType } from '@types';

@Component({
  selector: 'dc-vpc-edit-modal',
  templateUrl: './vpc-edit-modal.component.html',
  styleUrls: ['./vpc-edit-modal.component.sass'],
})
export class VpcEditModalComponent implements OnInit {
  @Input() isModalVisible = false;
  @Input() isModalLoading = false;

  @Input() selectedVpcEditType: VpcEditType;

  // ngModel
  @Input() vpc: VpcModel;
  @Output() vpcChange = new EventEmitter<VpcModel>();

  @Output() handleOk: EventEmitter<void> = new EventEmitter();
  @Output() handleCancel: EventEmitter<void> = new EventEmitter();

  @ViewChild('input') inputElement: ElementRef;

  vpcEditType = VpcEditType;

  constructor() {}

  ngOnInit(): void {}

  focus = () => {
    this.inputElement.nativeElement.focus();
  };

  get vpcEditTypeStr() {
    switch (this.selectedVpcEditType) {
      case VpcEditType.NAME:
        return '이름';
      case VpcEditType.REMOTE_ID:
        return '접속 아이디';
      case VpcEditType.REMOTE_PASSWORD:
        return '접속 비밀번호';
      case VpcEditType.TELEGRAM_CODE:
        return '텔레그램 아이디';
      default:
        return '알수 없음';
    }
  }
}
