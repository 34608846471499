<header id="header" class="flex">
  <div class="left-header header-txt">
    <img src="/assets/images/clouds.png" class="icon" (click)="navigationService.goToHomePage()" />
    <div (click)="navigationService.goToHomePage()">
      드림 클라우드
    </div>
  </div>
  <div class="flex1 align-right">
    <div class="nav">
      <a *ngIf="authService.isAuthenticated" nz-button nzType="link" class="blue" (click)="sendEmail()">문의하기</a>
      <a nz-button nzType="link" class="blue" (click)="goToMyPage()">MY</a>
      <a *ngIf="!authService.isAuthenticated" nz-button nzType="link" class="blue"
        (click)="navigationService.goToSignInPage()">로그인</a>
      <a *ngIf="authService.isAuthenticated" nz-button nzType="link" class="red" (click)="signOut()">로그아웃</a>
    </div>
  </div>
</header>