import { Component, Input, OnInit } from '@angular/core';
import { NavigationService } from '@core';
import { MasterMenuType } from '@types';

@Component({
  selector: 'dc-master-menu',
  templateUrl: './master-menu.component.html',
  styleUrls: ['./master-menu.component.sass'],
})
export class MasterMenuComponent implements OnInit {
  @Input() selectedMenuType = MasterMenuType.ORDER;

  menuType = MasterMenuType;

  constructor(public navigationService: NavigationService) {}

  ngOnInit(): void {}

  menuTypeToStr = (menuType: MasterMenuType): string => {
    switch (menuType) {
      case MasterMenuType.ORDER:
        return '주문 내역';
      case MasterMenuType.PAYMENT:
        return '결제 내역';
      case MasterMenuType.VPC:
        return '가상머신';
      default:
        return '알수없음';
    }
  };

  isSelected = (menuType: MasterMenuType): boolean => {
    return this.selectedMenuType === menuType;
  };
}
