import { VpcStateType } from '@types';
import { AuthUserModel } from '../auth';
import { ProductModel } from '../product';

export class VpcModel {
  vpcId: number;
  vpcName: string;
  remoteIp: string;
  remoteId: string;
  remotePasswd: string;
  telegramCode: string;
  vpcState: VpcStateType;
  startDate: Date;
  expireDate: Date;

  product: ProductModel;
  user: AuthUserModel;

  get vpcStateToStr(): string {
    switch (this.vpcState) {
      case VpcStateType.PENDING:
        return '생성 중';
      case VpcStateType.WAITING:
        return '대기';
      case VpcStateType.AVAILABLE:
        return '동작';
      case VpcStateType.TERMINATED:
        return '종료';
      default:
        return '알수없음';
    }
  }

  get vpcStateToColor(): string {
    switch (this.vpcState) {
      case VpcStateType.WAITING:
        return 'orange';
      case VpcStateType.PENDING:
        return 'purple';
      case VpcStateType.AVAILABLE:
        return 'blue';
      case VpcStateType.TERMINATED:
        return 'red';
      default:
        return 'purple';
    }
  }
}
