<div class="login-form-title">
  로그인
</div>
<form nz-form [formGroup]="validateForm" [nzLayout]="'vertical'" class="login-form" (ngSubmit)="submitForm()">
  <nz-form-item>
    <nz-form-label>이메일</nz-form-label>
    <nz-form-control nzErrorTip="이메일을 입력해주세요!">
      <nz-input-group>
        <input class="form-input" nz-input type="text" formControlName="email" placeholder="이메일" />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label>비밀번호</nz-form-label>
    <nz-form-control nzErrorTip="비밀번호를 입력해주세요!">
      <nz-input-group>
        <input class="form-input" nz-input [type]="'password'" placeholder="비밀번호" formControlName="passwd" />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <button nz-button class="form-input login-form-button login-form-margin" nzType="primary" [nzLoading]="isLoading"
    [disabled]="!validateForm.valid">
    로그인
  </button>
  <button *ngIf="!isMaster" nz-button class="form-input login-form-button login-form-margin" nzType="default"
    [disabled]="isMaster" (click)="onSignUpBtnClick($event)">
    회원가입
  </button>
</form>