<div class="order-list-wrapper">
  <div class="table-wrapper">
    <table width=100%>
      <thead>
        <tr>
          <th>주문 상태</th>
          <th>가상 계좌 이름</th>
          <th>가상 계좌 번호</th>
          <th>입금 금액</th>
          <th>가상 계좌 유효 기간</th>
          <th>예금주</th>
          <th>제품</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let order of orderList">
          <td nzBreakWord>
            {{order.orderStateStr}}
          </td>
          <td nzBreakWord>
            {{order.vbankName}}
          </td>
          <td nzBreakWord>
            {{order.vbankNum}}
          </td>
          <td nzBreakWord>
            ₩{{order.totalPayAmount | number:'':'en'}}
          </td>
          <td nzBreakWord>
            {{order.vbankDate | date:'yyyy-MM-dd hh:mm'}}
          </td>
          <td nzBreakWord>
            {{order.vbankHolder}}
          </td>
          <td nzBreakWord>
            <a nz-button nzType="link" (click)="onClickOrder(order)">보기</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <nz-pagination [nzPageIndex]="pageNumber" [nzPageSize]="pageSize" [nzTotal]="totalSize"
    (nzPageIndexChange)="onPageIndexChange($event)">
  </nz-pagination>
</div>
<dc-product-detail-modal #nameEditModal [products]="selectedProducts" [isModalVisible]="isDetailModalVisible"
  (handleCancel)="handleCancel()">
</dc-product-detail-modal>