import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProductModel } from '@models';

@Component({
  selector: 'dc-product-detail-modal',
  templateUrl: './product-detail-modal.component.html',
  styleUrls: ['./product-detail-modal.component.sass'],
})
export class ProductDetailModalComponent {
  @Input() isModalVisible = false;

  @Input() products: ProductModel[] = [];
  @Output() handleCancel: EventEmitter<void> = new EventEmitter();
}
