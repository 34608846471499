export enum MenuType {
  ORDER = 'ORDER',
  PAYMENT = 'PAYMENT',
  VPC = 'VPC',
  MY_PAGE = 'MY_PAGE',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
}

export enum MasterMenuType {
  ORDER = 'ORDER',
  PAYMENT = 'PAYMENT',
  VPC = 'VPC',
}
