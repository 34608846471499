<nz-modal [(nzVisible)]="isModalVisible" [nzTitle]="modalTitle" [nzContent]="modalContent" [nzFooter]="modalFooter"
  [nzMaskClosable]="true" (nzOnCancel)="handleCancel.emit()">
  <ng-template #modalTitle>제품 정보</ng-template>
  <ng-template #modalContent>
    <div *ngFor="let product of products">
      <div class="info-row">
        <span class="info-title">제품명 : </span>
        <span class="info-body">{{product.productName}}</span>
      </div>
      <div class="info-row">
        <span class="info-title">메모리 : </span>
        <span class="info-body">{{product.machineLearning ? '별도문의' : product.ram}}GB</span>
      </div>
      <div class="info-row">
        <span class="info-title">CPU : </span>
        <span class="info-body">{{product.machineLearning ? '별도문의' : product.cpu}} vCPU</span>
      </div>
      <div class="info-row">
        <span class="info-title">저장소 : </span>
        <span class="info-body">{{product.machineLearning ? '별도문의' : product.storage}} GB</span>
      </div>
      <div class="info-row">
        <span class="info-title">가격 : </span>
        <span class="info-body">₩{{product.sellPrice | number:'':'en'}}</span>
      </div>
    </div>
  </ng-template>
  <ng-template #modalFooter>
    <button nz-button nzType="primary" (click)="handleCancel.emit()">확인</button>
  </ng-template>
</nz-modal>