import { Injectable } from '@angular/core';

import * as moment from 'moment';

@Injectable()
export class CommonService {
  constructor() {}

  formatNumber = (value: string | number): string => {
    if (isNaN(Number(value))) {
      return '';
    }
    const stringValue = `${value}`;
    const list = stringValue.split('.');
    const prefix = list[0].charAt(0) === '-' ? '-' : '';
    let num = prefix ? list[0].slice(1) : list[0];
    let result = '';
    while (num.length > 3) {
      result = `,${num.slice(-3)}${result}`;
      num = num.slice(0, num.length - 3);
    }
    if (num) {
      result = num + result;
    }
    return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
  };

  formatToNumber = (value: string): number => {
    return +value.replace(/[^0-9]+/g, '');
  };

  formatDateStr = (value: Date): string => {
    if (!value) {
      return '';
    }
    return moment(value).utcOffset(540).format('YYYY-MM-DD');
  };
}
