import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { JwtHelperService } from '@auth0/angular-jwt';

import { SessionStorageService } from '../session-storage';
import { ContentService } from '../content';
import { AuthMasterModel, AuthUserModel, TokenModel } from '@models';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';

@Injectable()
export class AuthService {
  constructor(
    private http: HttpClient,
    private contentService: ContentService,
    // private deviceService: DeviceDetectorService,
    private jwtHelperService: JwtHelperService,
    private sessionStorageService: SessionStorageService,
  ) {}

  private userUrl = `/auth/user`;
  private masterUrl = `/auth/master`;

  signIn = async (loginId: string, passwd: string, isMaster?: boolean): Promise<void> => {
    const params = isMaster ? { loginId, passwd } : { email: loginId, passwd };
    try {
      const token = await this.http
        .post<TokenModel>(`/auth/${isMaster ? 'master' : 'user'}/login`, params, {
          headers: { ignoreToken: 'true' },
        })
        .toPromise();
      this.sessionStorageService.saveTokens(token);
    } catch (ex) {
      await this.signOut();
      throw new Error(ex);
    }
  };

  refresh = (): Observable<TokenModel> => {
    return this.http.post<TokenModel>(`${this.url}/refresh`, {}).pipe(
      tap((token: TokenModel) => {
        this.sessionStorageService.saveAccessToken(token.accessToken);
      }),
    );
  };

  signOut = async () => {
    this.contentService.signOut();
  };

  get isAuthenticated(): boolean {
    const token = this.sessionStorageService.accessToken;
    if (!token) {
      return false;
    }
    try {
      return !this.jwtHelperService.isTokenExpired(token);
    } catch (ex) {
      return false;
    }
  }

  get isMaster(): boolean {
    const token = this.sessionStorageService.refreshToken;
    if (!token) {
      return false;
    }
    try {
      const authUser = plainToClass(AuthMasterModel, this.jwtHelperService.decodeToken(token));
      return authUser.isMaster;
    } catch (ex) {
      return false;
    }
  }

  get isUser(): boolean {
    const token = this.sessionStorageService.refreshToken;
    if (!token) {
      return false;
    }
    try {
      const authUser = plainToClass(AuthUserModel, this.jwtHelperService.decodeToken(token));
      return authUser.isUser;
    } catch (ex) {
      return false;
    }
  }

  get email(): string {
    const token = this.sessionStorageService.accessToken;
    if (!token) {
      return '';
    }
    try {
      const authUser = plainToClass(AuthUserModel, this.jwtHelperService.decodeToken(token));
      return authUser.email;
    } catch (ex) {
      return '';
    }
  }

  get url(): string {
    return this.isMaster ? this.masterUrl : this.userUrl;
  }
}
