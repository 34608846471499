import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ContentService {
  private signOutSrc = new Subject();
  signOut$ = this.signOutSrc.asObservable();

  public signOut = () => {
    this.signOutSrc.next();
  };
}
