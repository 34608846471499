import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService, ContentService, SessionStorageService } from '@core';

import { Subscription } from 'rxjs';
import { map, filter, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];

  hideTab = true;
  hideFooter = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    public authService: AuthService,
    private contentService: ContentService,
    private sessionStorageService: SessionStorageService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.subs.push(
      this.router.events
        .pipe(
          filter(events => events instanceof NavigationEnd),
          map(() => this.activatedRoute),
          map(route => {
            while (route.firstChild) {
              route = route.firstChild;
            }
            return route;
          }),
        )
        .pipe(
          filter(route => route.outlet === 'primary'),
          mergeMap(route => route.data),
        )
        .subscribe(x => {
          this.hideTab = x.hideHeader === true;
          this.hideFooter = this.hideTab || x.hideFooter === true;
        }),
      this.contentService.signOut$.subscribe(() => {
        this.sessionStorageService.deleteTokens();
      }),
    );
  }

  ngOnDestroy(): void {
    this.subs.map(sub => sub && sub.unsubscribe());
  }
}
