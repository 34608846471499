import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageModel, PagingModel, VpcModel } from '@models';
import { VpcStateType } from '@types';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth';

@Injectable()
export class VpcService {
  userUrl = 'api/user/vpc';
  masterUrl = 'api/master/vpc';

  constructor(private http: HttpClient, private authService: AuthService) {}

  loadVpcList = (
    pageNumber: number,
    pageSize: number,
    vpcStateType?: VpcStateType,
  ): Observable<PageModel<VpcModel>> => {
    let params = new HttpParams()
      .append('pageNumber', pageNumber.toString())
      .append('pageSize', pageSize.toString());

    if (vpcStateType) {
      params = params.append('vpcState', vpcStateType.toString());
    }
    return this.http.get<PagingModel<VpcModel>>(`${this.url}`, { params }).pipe(
      map(result => {
        result.page.content = result.page.content.map(vpc => plainToClass(VpcModel, vpc));
        return result.page;
      }),
    );
  };

  loadVpc = (vpcId: number): Observable<VpcModel> => {
    return this.http
      .get<{ vpc: VpcModel }>(`${this.url}/${vpcId}`)
      .pipe(map(result => plainToClass(VpcModel, result.vpc)));
  };

  updateName = (vpcName: string, vpcId: number) => {
    return this.http.patch(`${this.url}/${vpcId}/name`, { vpcName });
  };

  updateTelegramCode = (telegramCode: string, vpcId: number) => {
    return this.http.patch(`${this.url}/${vpcId}/telegram`, { telegramCode });
  };

  get url() {
    return this.authService.isMaster ? this.masterUrl : this.userUrl;
  }
}
