import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// 3rd party
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';

import ko from '@angular/common/locales/ko';
import { registerLocaleData } from '@angular/common';

import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';

import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { NZ_I18N, ko_KR } from 'ng-zorro-antd/i18n';

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key]);

registerLocaleData(ko);

import {
  AuthUserGuardService,
  AuthService,
  AuthMasterGuardService,
  AuthOnlyUserGuardService,
} from './auth';
import { BannerService } from './banner';
import { ContentService } from './content';
import { SessionStorageService } from './session-storage';
import { MemberService } from './member';
import { ModalService } from './modal';
import { MyInfoService } from './my-info';
import { NavigationService } from './navigation';
import { RequestInterceptor } from './interceptors';
import { CommonService } from './common';
import { ImageService } from './image';
import { ProductService } from './product';
import { IamportService } from './iamport';
import { OrderService } from './order';
import { VpcService } from './vpc';
import { PaymentService } from './payment';

@NgModule({
  declarations: [],
  providers: [
    AuthMasterGuardService,
    AuthOnlyUserGuardService,
    AuthUserGuardService,
    AuthService,
    BannerService,
    CommonService,
    ContentService,
    IamportService,
    ImageService,
    MemberService,
    ModalService,
    MyInfoService,
    NavigationService,
    OrderService,
    PaymentService,
    SessionStorageService,
    ProductService,
    VpcService,
    // third party
    JwtHelperService,
    { provide: NZ_I18N, useValue: ko_KR },
    { provide: NZ_ICONS, useValue: icons },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
  ],
  imports: [CommonModule, HttpClientModule, HttpClientJsonpModule],
  exports: [],
})
export class CoreModule {}
