import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OrderModel, PageModel, PagingModel } from '@models';
import { OrderState, OrderType } from '@types';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth';

@Injectable()
export class OrderService {
  userUrl = 'api/user/order';
  masterUrl = 'api/master/order';

  constructor(private http: HttpClient, private authService: AuthService) {}

  renew = (
    vpcId: number,
    impUid: string,
    orderNo: string,
    productId: number,
    vpcName: string,
    telegramCode: string,
  ) => {
    return this.http.post(`${this.url}/extension/vpc/${vpcId}`, {
      impUid,
      orderNo,
      productId,
      vpcName,
      telegramCode,
    });
  };

  loadOrder = (orderId: number): Observable<OrderModel> => {
    return this.http
      .get<{ order: OrderModel }>(`${this.url}/${orderId}`)
      .pipe(map(result => plainToClass(OrderModel, result.order)));
  };

  loadOrderList = (
    pageNumber: number,
    pageSize: number,
    orderState?: OrderState,
  ): Observable<PageModel<OrderModel>> => {
    let query = ``;
    if (orderState) {
      query = `&orderState=${orderState}`;
    }
    return this.http
      .get<PagingModel<OrderModel>>(
        `${this.url}?pageNumber=${pageNumber}&pageSize=${pageSize}${query}`,
      )
      .pipe(
        map(result => {
          result.page.content = result.page.content.map(payment =>
            plainToClass(OrderModel, payment),
          );
          return result.page;
        }),
      );
  };

  completeOrder = (
    impUid: string,
    orderNo: string,
    productId: number,
    vpcName: string,
    telegramCode: string,
  ): Observable<number> => {
    return this.http
      .post<{ order: OrderModel }>(`${this.userUrl}/complete`, {
        impUid,
        orderNo,
        productId,
        vpcName,
        telegramCode,
      })
      .pipe(map(result => result.order.orderId));
  };

  loadOrderNumber = (orderType: OrderType): Observable<string> => {
    return this.http
      .get<{ orderNo: string }>(`${this.url}/orderNo?type=${orderType}`)
      .pipe(map(result => result.orderNo));
  };

  get url() {
    return this.authService.isMaster ? this.masterUrl : this.userUrl;
  }
}
