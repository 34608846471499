import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ContentService } from '../content';
import { NavigationService } from '../navigation';

import { AuthService } from './auth.service';

@Injectable()
export class AuthUserGuardService implements CanActivate {
  constructor(
    private authService: AuthService,
    private contentService: ContentService,
    private navigationService: NavigationService,
  ) {}

  canActivate(): boolean {
    if (
      !this.authService.isAuthenticated ||
      !(this.authService.isMaster || this.authService.isUser)
    ) {
      this.contentService.signOut();
      this.navigationService.goToSignInPage();
      return false;
    }
    return true;
  }
}
