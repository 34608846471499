import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { DemoNgZorroAntdModule } from './ng-zorro-antd.module';

import { BannerComponent } from './banner';
import { FooterComponent } from './footer';
import { HeaderComponent } from './header';
import { ProductComponent } from './product';
import { ProductDetailModalComponent } from './product-detail-modal';
import { SignInFormComponent } from './sign-in-form';
import { SignUpFormComponent } from './sign-up-form';

import { PaymentListComponent } from './payment-list';
import { MasterMenuComponent } from './master-menu';
import { MenuComponent } from './menu';
import { TermComponent } from './term';

import { VpcListComponent } from './vpc-list';
import { VpcDetailModalComponent } from './vpc-detail-modal';
import { VpcEditModalComponent } from './vpc-edit-modal';
import { ConnectWayModalComponent } from './connect-way-modal';
import { OrderListComponent } from './order-list';

@NgModule({
  imports: [
    CommonModule,
    DemoNgZorroAntdModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollingModule,
    DragDropModule,
  ],
  declarations: [
    BannerComponent,
    FooterComponent,
    HeaderComponent,
    MasterMenuComponent,
    MenuComponent,
    OrderListComponent,
    PaymentListComponent,
    ProductComponent,
    ProductDetailModalComponent,
    SignInFormComponent,
    SignUpFormComponent,
    TermComponent,
    VpcDetailModalComponent,
    VpcListComponent,
    VpcEditModalComponent,
    ConnectWayModalComponent,
  ],
  exports: [
    DemoNgZorroAntdModule,
    // component
    BannerComponent,
    FooterComponent,
    HeaderComponent,
    MasterMenuComponent,
    MenuComponent,
    OrderListComponent,
    PaymentListComponent,
    ProductComponent,
    ProductDetailModalComponent,
    SignInFormComponent,
    SignUpFormComponent,
    TermComponent,
    VpcDetailModalComponent,
    VpcListComponent,
    VpcEditModalComponent,
    ConnectWayModalComponent,
  ],
})
export class SharedModule {}
