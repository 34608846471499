import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '@core';
import { passwordRegex } from '@regex';
import { TermType } from '@types';

import { TermComponent } from '../term';

@Component({
  selector: 'dc-sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styleUrls: ['./sign-up-form.component.sass'],
})
export class SignUpFormComponent implements OnInit {
  validateForm!: FormGroup;
  passwordVisible = false;

  termType = TermType;

  @Input() isLoading = false;
  @Output() signUp: EventEmitter<any> = new EventEmitter();

  constructor(private fb: FormBuilder, private modalService: ModalService) {}

  ngOnInit(): void {
    this.initForm();
  }

  private initForm = () => {
    this.validateForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      passwd: [
        null,
        [Validators.required, Validators.minLength(8), Validators.pattern(passwordRegex)],
      ],
      passwdConfirm: [null, [Validators.required, this.confirmationValidator]],
      serviceTermAgreed: [false, [Validators.pattern('true')]],
      privacyTermAgreed: [false, [Validators.pattern('true')]],
    });
  };

  submitForm = (): void => {
    for (const i in this.validateForm.controls) {
      if (i) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    }
    if (this.validateForm.valid) {
      this.signUp.emit(this.validateForm.value);
    }
  };

  openTermModal = (termType: TermType, event: any) => {
    event.preventDefault();
    this.modalService.create('', TermComponent, 1000, { termType });
  };

  private confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.validateForm.controls.passwd.value) {
      return { confirm: true, error: true };
    }
    return {};
  };
}
