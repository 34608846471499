import { Component, OnInit } from '@angular/core';
import { PaymentService } from '@core';
import { PaymentModel } from '@models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'dc-payment-list',
  templateUrl: './payment-list.component.html',
  styleUrls: ['./payment-list.component.sass'],
})
export class PaymentListComponent implements OnInit {
  pageNumber = 1;
  pageSize = 20;
  totalSize = 0;

  paymentList: PaymentModel[] = [];

  subscription?: Subscription;

  constructor(private paymentService: PaymentService) {}

  ngOnInit(): void {
    this.loadPaymentList();
  }

  loadPaymentList = () => {
    this.subscription && this.subscription.unsubscribe();
    this.subscription = this.paymentService
      .loadPaymentList(this.pageNumber, this.pageSize)
      .subscribe(result => {
        this.paymentList = result.content;
        this.totalSize = result.totalElements;
      });
  };

  onPageIndexChange = async (pageIdx: number) => {
    this.pageNumber = pageIdx;
    this.loadPaymentList();
  };
}
