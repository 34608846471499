import { PaymentType } from '@types';

export class PaymentModel {
  logId: number;
  vpcName: string;
  payMethod: PaymentType;
  payAmount: number;
  createdAt: Date;
  userId: number;
  email: string;
  productId: number;
  productName: string;
  productImageUrl: string;
  orderNo: string;
  cpu: number;
  ram: string;
  storage: string;
  memo: string;
  sellPrice: number;
  remoteId: string;
  remotePasswd: string;
  telegramCode: string;
  machineLearning: boolean;

  get paymentMethodStr(): string {
    switch (this.payMethod) {
      case PaymentType.CARD:
        return '카드결제';
      case PaymentType.VBANK:
        return '가상계좌';
      default:
        return '알수없음';
    }
  }
}
