<ul nz-menu nzMode="inline">
  <li>
    <ul>
      <li nz-menu-group nzTitle="MY">
        <ul>
          <li [nzSelected]="isSelected(menuType.ORDER)" nz-menu-item
            (click)="navigationService.goToPageByMenuType(menuType.ORDER)">
            {{menuTypeToStr(menuType.ORDER)}}
          </li>
        </ul>
        <ul>
          <li [nzSelected]="isSelected(menuType.PAYMENT)" nz-menu-item
            (click)="navigationService.goToPageByMenuType(menuType.PAYMENT)">
            {{menuTypeToStr(menuType.PAYMENT)}}
          </li>
        </ul>
        <ul>
          <li [nzSelected]="isSelected(menuType.VPC)" nz-menu-item
            (click)="navigationService.goToPageByMenuType(menuType.VPC)">
            {{menuTypeToStr(menuType.VPC)}}
          </li>
        </ul>
        <ul>
          <li [nzSelected]="isSelected(menuType.CHANGE_PASSWORD)" nz-menu-item
            (click)="navigationService.goToPageByMenuType(menuType.CHANGE_PASSWORD)">
            {{menuTypeToStr(menuType.CHANGE_PASSWORD)}}
          </li>
        </ul>
      </li>
    </ul>
  </li>
</ul>