import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  AuthMasterGuardService,
  AuthOnlyUserGuardService,
  AuthUserGuardService,
} from './core/auth';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages').then(m => m.HomeModule),
  },
  {
    path: 'sign-in',
    data: { hideHeader: true },
    loadChildren: () => import('./pages').then(m => m.SignInModule),
  },
  {
    path: 'sign-up',
    data: { hideHeader: true },
    loadChildren: () => import('./pages').then(m => m.SignUpModule),
  },
  {
    path: 'master',
    canActivate: [AuthMasterGuardService],
    loadChildren: () => import('./pages').then(m => m.MasterModule),
  },
  {
    path: 'my-page',
    canActivate: [AuthOnlyUserGuardService],
    data: { hideFooter: true },
    loadChildren: () => import('./pages').then(m => m.MyPageModule),
  },
  {
    path: 'purchase',
    canActivate: [AuthUserGuardService],
    loadChildren: () => import('./pages').then(m => m.PurchaseModule),
  },
  {
    path: 'policy',
    loadChildren: () => import('./pages').then(m => m.PolicyModule),
  },
  {
    path: 'term',
    loadChildren: () => import('./pages').then(m => m.TermModule),
  },
  {
    path: 'email',
    data: { hideHeader: true },
    loadChildren: () => import('./pages').then(m => m.EmailModule),
  },
  {
    path: 'verification',
    data: { hideHeader: true },
    loadChildren: () => import('./pages').then(m => m.VerificationModule),
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
