<nz-modal [(nzVisible)]="isModalVisible" [nzTitle]="modalTitle" [nzContent]="modalContent" [nzFooter]="modalFooter"
  [nzMaskClosable]="true" (nzOnCancel)="handleCancel.emit()">
  <ng-template #modalTitle>{{vpcEditTypeStr}} 변경</ng-template>

  <ng-template #modalContent>
    <container-element [ngSwitch]="selectedVpcEditType">
      <input *ngSwitchCase="vpcEditType.NAME" #input type="text" [(ngModel)]="vpc.vpcName"
        class="edit-input align-center" />
      <input *ngSwitchCase="vpcEditType.TELEGRAM_CODE" #input type="text" [(ngModel)]="vpc.telegramCode"
        class="edit-input align-center" />
      <input *ngSwitchCase="vpcEditType.REMOTE_PASSWORD" #input type="text" [(ngModel)]="vpc.remotePasswd"
        class="edit-input align-center" />
    </container-element>
  </ng-template>

  <ng-template #modalFooter>
    <button nz-button nzType="primary" (click)="handleOk.emit()" [nzLoading]="isModalLoading">저장</button>
    <button nz-button nzType="default" (click)="handleCancel.emit()">취소</button>
  </ng-template>

</nz-modal>