export class ProductModel {
  productId?: number;
  productName!: string;
  productImageUrl!: string;
  displayPrice!: number;
  sellPrice!: number;
  memo!: string;
  cpu!: number;
  ram!: number;
  storage!: number;
  machineLearning: boolean;

  get imageUrl() {
    return this.machineLearning ? '/assets/images/nvidia.png' : '/assets/images/window10.jpeg';
  }
}
